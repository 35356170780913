import { Image } from '@graphcommerce/image'
import { useDisplayInclTax } from '@graphcommerce/magento-cart'
import { CartItemFragment } from '@graphcommerce/magento-cart-items/Api/CartItem.gql'
import { useProductLink } from '@graphcommerce/magento-product'
import { Money } from '@graphcommerce/magento-store'
import { filterNonNullableKeys } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Paper, SxProps, Theme } from '@mui/material'
import { default as Link, default as PageLink } from 'next/link'
import React, { useMemo } from 'react'
import { getEstShipDate } from '../../../lib/etc/date'
import { ItemArtworkManager } from '../../Artwork/ItemArtworkManager'
import { PitchPrint } from '../../PitchPrint/PitchPrint'
import { RemoveItemFromCartFab } from '../RemoveItemFromCart/RemoveItemFromCartFab'
import { ConfigurableCartItemOptionsFragment } from './CartItem.gql'

export type CartItemProps = CartItemFragment & {
  prices?: CartItemFragment['prices'] & Partial<ConfigurableCartItemOptionsFragment['prices']>
  artwork?: ConfigurableCartItemOptionsFragment['artwork']
  projectId?: string | null
  reorder?: boolean | null
  disableEditing?: boolean
  product?: CartItemFragment['product'] & { requires_artwork?: boolean | null }
  displayAsCartItem?: boolean
} & {
  sx?: SxProps<Theme>
  children?: React.ReactNode
} & OwnerState & {
    showSummary?: boolean
  }

type OwnerState = { withOptions?: boolean }

export function CartItem(props: CartItemProps) {
  const {
    product,
    errors,
    uid,
    prices,
    children,
    artwork,
    projectId,
    reorder,
    disableEditing,
    showSummary = true,
    withOptions = true,
    displayAsCartItem,
  } = props
  const { name } = product
  const productLink = useProductLink(product)
  const inclTaxes = useDisplayInclTax()

  const estimatedShippingDate = useMemo(() => {
    if (
      product.__typename === 'ConfigurableProduct' &&
      'production_time' in product &&
      product.production_time &&
      typeof product.production_time === 'number'
    ) {
      return getEstShipDate(product.production_time)
    }
    return null
  }, [product])

  const totalPrice = inclTaxes ? prices?.row_total_including_tax?.value : prices?.row_total?.value
  const totalTax = (prices?.row_total_including_tax?.value || 0) - (prices?.row_total?.value || 0)
  const totalPriceWithSetupPrice =
    totalPrice && prices && prices.setup_fee && typeof prices.setup_fee.value === 'number'
      ? totalPrice + prices.setup_fee.value
      : totalPrice

  return (
    <Paper
      elevation={displayAsCartItem ? 3 : 0}
      className={`cart-item mb-[24px] flex border-b-[1px] border-50-grey last:mb-0 last:border-none ${
        displayAsCartItem ? 'p-5' : ''
      }`}
      sx={{
        backgroundColor: displayAsCartItem ? '#00000005' : 'transparent',
        borderRadius: displayAsCartItem ? '8px' : '',
      }}
    >
      <div
        className={`${displayAsCartItem ? '' : 'mb-[20px]'} flex grow ${
          showSummary && !displayAsCartItem ? 'flex-col' : 'flex-col-reverse'
        } items-center gap-2 md:grid md:grid-cols-[70fr_30fr]`}
      >
        <div
          className={`flex w-[80%] ${
            displayAsCartItem ? 'grid-cols-[35fr_65fr]' : 'grid-cols-[40fr_60fr]'
          }  flex-col gap-[20px] md:mb-0 md:grid md:w-full`}
        >
          <div className='flex h-full flex-col gap-1 md:max-w-[160px]'>
            <PageLink href={productLink} passHref>
              {product?.thumbnail?.url && (
                <Box
                  component='a'
                  className=''
                  sx={{
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                    aspectRatio: '5/6',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px',
                    '@media (min-wdth: 768px)': {
                      maxWidth: '160px',
                    },
                  }}
                >
                  <Image
                    src={`${product.thumbnail.url ?? ''}`}
                    alt={`${product.thumbnail.label ?? ''}`}
                    unoptimized
                    height={30}
                    width={30}
                    layout='responsive'
                    className='h-full w-auto object-cover'
                    sx={{
                      aspectRatio: '5/6',
                    }}
                  />
                </Box>
              )}
            </PageLink>
            {!!projectId && (
              <PitchPrint
                projectId={projectId}
                productUid={product.uid}
                setPpProjectId={() => {}}
              />
            )}
            {product.requires_artwork && (
              <ItemArtworkManager
                artwork={artwork}
                cartItemUid={uid}
                requiresArtwork={product.requires_artwork}
                allowEdit={!reorder}
              />
            )}
          </div>

          <div className=''>
            <Link href={productLink} passHref className='Type-XL-Bold text-tight-black'>
              {name}
            </Link>
            <div className='my-[5px]'>{children}</div>
            <Box sx={{ gridArea: 'itemName' }}>
              {filterNonNullableKeys(errors).map((error) => (
                <Box sx={{ color: 'error.main', typography: 'caption' }} key={error.message}>
                  {error.message}
                </Box>
              ))}
            </Box>
            <hr className='my-4 w-full text-20-grey' />
            <div className='Type-Large-Regular flex items-center justify-between'>
              <span>
                {prices?.setup_fee ? <Money {...prices?.setup_fee} /> : <Money value={0} />} setup
              </span>
              <span>
                Total <Money {...prices?.row_total} />
              </span>
            </div>
          </div>
        </div>
        {showSummary && (
          <div className='flex h-full flex-col items-end justify-start md:mt-3'>
            <div>
              {estimatedShippingDate && (
                <div className='flex w-full items-center justify-between gap-[10px]'>
                  <span className='Type-Large-Regular text-tight-black'>
                    <Trans id='Est. Ship Date' />
                  </span>
                  <span className='Type-Large-Regular text-60-grey'>{estimatedShippingDate}</span>
                </div>
              )}
              {prices?.setup_fee && (
                <div className='mt-[10px] flex w-full items-center justify-between gap-[10px]'>
                  <span className='Type-Large-Regular text-tight-black'>
                    <Trans id='Setup Fee' />
                  </span>
                  <span className='Type-Large-Regular text-60-grey'>
                    <Money {...prices.setup_fee} />
                  </span>
                </div>
              )}

              <div className='mt-[10px] flex w-full items-center justify-between gap-[10px]'>
                <span className='Type-Large-Regular text-tight-black'>
                  <Trans id='Sales tax' />
                </span>
                <span className='Type-Large-Regular text-60-grey'>
                  <Money value={totalTax} currency={prices?.row_total?.currency} />
                </span>
              </div>

              <div className='mt-[18px] flex w-full items-center justify-between gap-[10px]'>
                <span className='Type-XL-Regular text-tight-black'>
                  <Trans id='Total' />
                </span>
                <span className='Type-XL-Regular text-tight-black'>
                  <Money
                    {...{
                      value: totalPriceWithSetupPrice || 0,
                      currency: prices?.row_total?.currency,
                    }}
                  />
                </span>
              </div>
            </div>
            {!disableEditing && (
              <div className='mt-[20px] flex w-full items-center justify-end gap-x-5 md:mb-[40px] md:mt-0'>
                <RemoveItemFromCartFab uid={uid} />
              </div>
            )}
          </div>
        )}
        {!disableEditing && !showSummary && (
          <div className='flex h-full w-full items-start justify-end gap-x-5 md:mb-[40px] md:mt-5'>
            <RemoveItemFromCartFab uid={uid} />
          </div>
        )}
      </div>
    </Paper>
  )
}
