// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { SxProps, TextField, styled } from '@mui/material'

export const StandardTextField = styled(TextField)((sx?: SxProps) => ({
  fontSize: 16,
  '&.MuiTextField-root': {
    outline: 'none',
    '& fieldset': {
      borderColor: 'rgba(0,0,0,0.3)',
    },
    height: '40px',
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    color: '#656565',
  },
  '& .MuiOutlinedInput-input': {
    height: '40px',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'none',
  },
  'MuiOutlinedInput-root': {
    outline: 'none',
    fontSize: '14px',
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    paddingLeft: '4px',
  },
}))
