import { useFormAutoSubmit } from '@graphcommerce/ecommerce-ui'
import { useFormGqlMutationCart } from '@graphcommerce/magento-cart'
import { CartItemFragment, CartItemProps } from '@graphcommerce/magento-cart-items'
import { Money } from '@graphcommerce/magento-store'
import { MenuItem } from '@mui/material'
import { useCallback } from 'react'
import { StandardDropdown } from '../../../Dropdown/StandardDropdown'
import { ConfigurableCartItemOptionsFragment } from '../CartItem.gql'
import { UpdateItemQuantityInCartDocument } from '../UpdateItemQuantityInCart.gql'

export function QuantityUpdate(
  props: CartItemFragment &
    CartItemProps & {
      configured_variant?: ConfigurableCartItemOptionsFragment['configured_variant']
    } & { className?: string },
) {
  const { configured_variant, uid, quantity, className = '' } = props

  const form = useFormGqlMutationCart(UpdateItemQuantityInCartDocument, {
    defaultValues: {
      cartItemId: uid,
      quantity,
    },
    mode: 'onChange',
  })
  const { handleSubmit, register, setValue, watch, formState } = form

  const submit = handleSubmit(() => {})
  useFormAutoSubmit({ form, submit })

  const quantityOptionLabelRenderer = useCallback((option, label, index) => {
    if (index === 0) {
      return label
    }

    if (typeof label === 'number') {
      return <Money value={label} formatOptions={{ maximumFractionDigits: index === 1 ? 4 : 2 }} />
    }
    const parts = label.split('#')
    return (
      <>
        <span className='line-through'>${parts[0]}</span> <br />{' '}
        <span className='text-brick'>${parts[1] || ''}</span>
      </>
    )
  }, [])

  const getProductPlusSetupPrice = useCallback(
    (tierQuantity: number, unitPrice: number) => {
      const rowPrice = unitPrice * tierQuantity
      let setupPrice = 0
      if (configured_variant) {
        const { setup_price, setup_price_discount, setup_price_quantity_threshold } =
          configured_variant
        if (setup_price_quantity_threshold && tierQuantity > setup_price_quantity_threshold) {
          setupPrice = 0
        } else if (setup_price_discount) {
          return `${rowPrice + (setup_price as number)}#${rowPrice + setup_price_discount}`
        }
      }

      return rowPrice + setupPrice
    },
    [configured_variant],
  )

  const options =
    configured_variant &&
    ['SimpleProduct', 'VirtualProduct', 'DownloadableProduct'].includes(
      configured_variant.__typename,
    ) &&
    configured_variant.price_tiers
      ? configured_variant.price_tiers?.map((tier) => {
          const unitPrice = tier?.final_price?.value

          return {
            value: tier?.quantity || '',
            labels: [tier?.quantity?.toLocaleString() || '', (unitPrice || 0) as unknown as number],
          }
        })
      : []

  const breakpointMenuItems = options.map((option) => (
    <MenuItem
      value={option.value}
      className='flex justify-between pr-5'
      sx={{ justifyContent: 'space-between' }}
    >
      {option.labels?.map((item, index) =>
        index === 0 ? <span>{item}</span> : <span>{`$${item} each`}</span>,
      )}
    </MenuItem>
  ))
  return (
    <div className={className}>
      <form onSubmit={submit} noValidate>
        <input type='hidden' {...register('quantity')} />
      </form>
      <StandardDropdown
        value={watch('quantity')}
        label='Quantity'
        onChange={(selectedOption) => {
          if (selectedOption && selectedOption?.target?.value)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setValue('quantity', parseFloat(selectedOption?.target?.value?.toString()), {
              shouldDirty: true,
            })
        }}
        options={breakpointMenuItems}
        twoColumns
        fullWidth
      />
      {/* <Dropdown
        value={watch('quantity')}
        options={
          configured_variant && configured_variant.price_tiers
            ? configured_variant.price_tiers?.map((tier) => {
                const unitPrice = tier?.final_price?.value

                return {
                  value: tier?.quantity || '',
                  labels: [
                    tier?.quantity?.toLocaleString() || '',
                    (unitPrice || 0) as unknown as number,
                    tier && typeof tier?.quantity === 'number' && typeof unitPrice === 'number'
                      ? getProductPlusSetupPrice(tier?.quantity, unitPrice)
                      : '',
                  ],
                }
              })
            : []
        }
        topLabels={['Quantity', 'Unit Price', 'Total']}
        showLabel={false}
        className='w-full'
        onChange={(selectedOption) => {
          if (selectedOption && selectedOption.value)
            setValue('quantity', parseFloat(selectedOption.value.toString()), {
              shouldDirty: true,
            })
        }}
        optionLabelRenderer={quantityOptionLabelRenderer}
        loading={formState.isSubmitting}
        labelWidths={[1, 1, 1]}
      /> */}
    </div>
  )
}
