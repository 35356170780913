export const addWeekDays = (d: Date, n) => {
  const date = new Date(d.getTime())
  const day = date.getDay()
  date.setDate(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/restrict-plus-operands
    date.getDate() + n + (day === 6 ? 2 : +!day) + Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2,
  )
  return date
}

export const getEstShipDate = (production_time: number) => {
  const currentDate = new Date()
  const estDate = addWeekDays(currentDate, production_time)
  const computedDate = new Date(
    estDate.getUTCFullYear(),
    estDate.getUTCMonth(),
    estDate.getUTCDate(),
  )
  const computedDateArry = computedDate.toDateString().split(' ')
  const dayNum =
    computedDateArry[2].indexOf('0') === 0 ? computedDateArry[2].substring(1) : computedDateArry[2]

  return `${computedDateArry[1]} ${dayNum}`
}
