import { useQuery } from '@graphcommerce/graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { i18n } from '@lingui/core'
import { Button } from '@mui/material'
import Script from 'next/script'
import { useCallback, useEffect, useState } from 'react'

type PitchPrintProps = {
  projectId?: string | null
  productUid: string
  designId?: string
  setPpProjectId: (value: string) => void
}

type ProjectSaveEventData = {
  projectId: string
  previews: [string]
}

export function PitchPrint(props: PitchPrintProps) {
  const { projectId, productUid, designId, setPpProjectId } = props
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [jqueryScriptLoaded, setJqueryScriptLoaded] = useState(false)
  const [shouldShow, setShouldShow] = useState(false)

  const { data: storeConfigQuery } = useQuery(StoreConfigDocument)
  const apiKey = storeConfigQuery?.storeConfig?.pitch_print_api_key

  const handleProjectSave = useCallback(
    (event: { data: ProjectSaveEventData }) => {
      setPpProjectId(event.data.projectId)
    },
    [setPpProjectId],
  )

  const launchApp = useCallback(() => {
    setShouldShow(true)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof PitchPrintClient !== 'function') {
      return
    }
    console.log('designId', designId)
    console.log('projectId', projectId)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.ppclient = new PitchPrintClient({
      displayMode: 'mini',
      uploadUrl: 'url_pending', // this was in the magento extension
      userId: '1', // this was in the magento extension
      langCode: 'en', // this was in the magento extension
      enableUpload: 0, // this was in the magento extension
      designId, // pass the design id from product
      previews: '', // this was in the magento extension
      mode: projectId ? 'edit' : 'new', // edit or new depending on if editing project
      createButtons: true,
      projectId,
      pluginRoot: 'path_pending',
      apiKey,
      client: 'mg',
      product: {
        id: productUid, // PASS THE PRODUCT ID
        name: '', // this was in the magento extension
      },
      userData: {
        email: '', // this was in the magento extension
        name: '', // this was in the magento extension
        firstname: '', // this was in the magento extension
        lastname: '', // this was in the magento extension
        telephone: '', // this was in the magento extension
        address: '', // this was in the magento extension
      },
    })
    globalThis.ppclient.on('project-saved', handleProjectSave)
    globalThis.ppclient.on('after-close-app', () => {
      setShouldShow(false)
    })
    globalThis.ppclient.showApp()
  }, [productUid, projectId, designId, handleProjectSave, apiKey])

  useEffect(() => {
    if (scriptLoaded && jqueryScriptLoaded) {
      // launchApp()
    }
  }, [scriptLoaded, jqueryScriptLoaded, launchApp])

  if (!apiKey) {
    return null
  }

  return (
    <div className='mt-2'>
      <Script
        src='https://pitchprint.io/rsc/js/client.js'
        strategy='afterInteractive'
        onLoad={() => setScriptLoaded(true)}
      />
      <Script
        src='https://code.jquery.com/jquery-3.1.1.min.js'
        strategy='afterInteractive'
        onLoad={() => setJqueryScriptLoaded(true)}
      />
      <Button
        color='primary'
        className='w-full min-w-[150px] bg-pure-white uppercase'
        variant='outlined'
        onClick={() => {
          launchApp()
        }}
        size='small'
      >
        {projectId ? i18n._(/* i18n */ 'Edit design') : i18n._(/* i18n */ 'Customize')}
      </Button>
    </div>
  )
}
