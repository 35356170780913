import { Select, SxProps, styled } from '@mui/material'

export const StandardSelect = styled(Select)((sx?: SxProps) => ({
  lineHeight: '24px',
  height: '40px',
  '&.MuiFormLabel-root &.MuiInputLabel-root': {
    fontSize: '14px',
  },
}))

export const DualValueSelect = styled(Select)((sx?: SxProps) => ({
  lineHeight: '24px',
  height: '40px',
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '20px',
  },
}))
