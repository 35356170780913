import { ApolloCartErrorSnackbar, useFormGqlMutationCart } from '@graphcommerce/magento-cart'
import {
  RemoveItemFromCartDocument,
  RemoveItemFromCartMutationVariables,
} from '@graphcommerce/magento-cart-items/RemoveItemFromCart/RemoveItemFromCart.gql'
import { i18n } from '@lingui/core'
import { Button, SxProps, Theme, styled } from '@mui/material'
import { useEffect, useState } from 'react'
// import { Button } from '../../Button'
import { ConfirmationPrompt } from '../../Prompt/ConfirmationPrompt'
import { Spinner } from '../../Spinner'

export type RemoveItemFromCartProps = Omit<RemoveItemFromCartMutationVariables, 'cartId'> &
  Omit<JSX.IntrinsicElements['form'], 'onSubmit' | 'noValidate'> & { sx?: SxProps<Theme> }

const Form = styled('form')({})

export function RemoveItemFromCartFab(props: RemoveItemFromCartProps) {
  const { uid, ...formProps } = props
  const form = useFormGqlMutationCart(RemoveItemFromCartDocument, { defaultValues: { uid } })
  const { handleSubmit, formState, error } = form
  const submitHandler = handleSubmit(() => {})
  const [displayWarning, setIsDisplayWarning] = useState<boolean>(false)
  const [response, setResponse] = useState<boolean | undefined>()

  useEffect(() => {
    const submit = async () => {
      if (response) {
        await submitHandler()
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    submit().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  if (formState.isSubmitting) {
    return <Spinner />
  }

  const handleOnClick = () => {
    setIsDisplayWarning(true)
  }

  return (
    <button type='button'>
      <div className='flex h-[30px] items-end justify-center' title='Delete this item'>
        <Button
          variant='text'
          color='error'
          className='px-2'
          aria-label={i18n._(/* i18n */ 'Remove Product')}
          type='button'
          onClick={handleOnClick}
          disabled={formState.isSubmitting}
          size='small'
        >
          Remove
        </Button>
        {/* <button
          aria-label={i18n._( 'Remove Product')}
          type='button'
          onClick={handleOnClick}
          disabled={formState.isSubmitting}
        >
          <Icon name='delete' className='text-[20px] text-red' />
        </button> */}
        <ApolloCartErrorSnackbar error={error} />
      </div>
      <ConfirmationPrompt
        title={i18n._('Remove from cart')}
        message={i18n._('Do you want to delete this item from your cart?')}
        isDisplayed={displayWarning}
        setIsDisplayed={setIsDisplayWarning}
        setResponse={setResponse}
      />
    </button>
  )
}
